import { useLoaderData } from '@remix-run/react';

import { LeapFeatures, BlockTypes } from '-/leap-models';
import {
  LinkInBio,
  useBrandContext,
  ThemeTheLeap,
  PLGFooterTypes,
} from '-/leap-pages';
import { useFeaturesContext } from '~/providers/FeaturesProvider';
import { useCreatorStoreRootData } from '../creator.$handle';

import type { AllBlockType } from '~/storefront/types/blocks';

export { action } from './handlers/action.server';
export { loader, headers } from '~/storefront/handlers/storeLoader.server';

interface LoaderData {
  storeCreatorLoggedIn: boolean;
  storefrontData: {
    status: string;
    referralCode: string;
    blocks: AllBlockType[];
  };
}

export default function Store() {
  const {
    storeCreatorLoggedIn,
    storefrontData: { referralCode, blocks },
  } = useLoaderData<LoaderData>();
  const { status } = useCreatorStoreRootData();

  const {
    brand: { theme, images, profileSummary, name, socialMedia },
  } = useBrandContext();

  const { isFeatureEnabled } = useFeaturesContext();

  return (
    <LinkInBio
      name={name}
      profileSummary={profileSummary}
      images={images}
      theme={theme || ThemeTheLeap}
      socialMedia={socialMedia}
      blocks={blocks.map((block: any) => ({
        ...block,
        to: [BlockTypes.LINK, BlockTypes.REFERRAL_LINK].includes(
          block.blockType.type
        )
          ? block.url
          : block.slug,
      }))}
      showEmptyMessage={storeCreatorLoggedIn}
      plgType={getPlgType(
        status,
        isFeatureEnabled(LeapFeatures.REMOVE_LEAP_BRANDING)
      )}
      plgReferralCode={referralCode}
    />
  );
}

function getPlgType(status: string, removeBrandingEnabled: boolean) {
  if (status === 'OUTREACH_PREVIEW') return PLGFooterTypes.CLAIM;
  if (removeBrandingEnabled) return PLGFooterTypes.LINK;
  return PLGFooterTypes.BUTTON;
}
